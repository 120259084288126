import { createContext, PropsWithChildren, useContext } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { useApi } from "./api.context";
import { IPermissions } from "../models/permissions.model";
import { usePermissions } from "../hooks/permissions.hooks";

export interface IAppContext {
	isMobile: boolean;
	permissions: IPermissions;
}

export const AppContext = createContext<IAppContext>(
	{ ...{} as IAppContext },
);

export function AppProvider(props: PropsWithChildren) {
	const api = useApi();
	const isMobile = useMediaQuery("(max-width: 768px)",
		typeof window !== "undefined" ? window.innerWidth <= 768 : false,
		{ getInitialValueInEffect: false },
	);

	const permissions = usePermissions(api.user?.roleId);

	return (
		<AppContext.Provider
			value={{
				isMobile,
				permissions,
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
}

export const useApp = (): IAppContext => useContext(AppContext);