import Seo from "../components/seo/seo";
import {
	ActionIcon,
	Center,
	Group,
	Header,
	Paper,
	Stack,
	Text,
	Title,
	Tooltip,
} from "@mantine/core";
import { TbMoon, TbSun } from "react-icons/tb";
import { useTheme } from "../context/theme.context";
import Image from "next/image";
import Link from "next/link";

export default function Offline() {
	const theme = useTheme();
	const colorScheme = useTheme();

	return (
		<Stack
			spacing={0}
			h={"100%"}
		>
			<Seo
				title={"Offline"}
				description={`CHECK5 Offline`}
			/>
			<Header
				height={60}
				px={"lg"}
			>
				<Group
					position={"apart"}
					h={60}
					noWrap
				>
					<Group noWrap>
						<Image
							src={"/logo.png"}
							alt={"logo"}
							height={40}
							width={35}
							style={{ width: "auto" }}
						/>
						<Title
							weight={500}
							truncate
						>Check5</Title>
					</Group>

					<Tooltip label={colorScheme.isDark ? "Light mode" : "Dark mode"}>
						<ActionIcon
							color={colorScheme.isDark ? "yellow" : "blue"}
							onClick={() => colorScheme.toggleColorScheme()}
							size={"lg"}
						>
							{colorScheme.isDark ? <TbSun size={18}/> : <TbMoon size={18}/>}
						</ActionIcon>
					</Tooltip>
				</Group>
			</Header>
			<Center h={"100%"}>
				<Stack>
					<Paper>
						<Stack
							p={"lg"}
							maw={600}
							justify={"center"}
						>
							<Title
								weight={500}
								align={"center"}
							>OFFLINE</Title>
							<Text align={"center"}>Check5 is currently offline for maintenance</Text>
							<Text align={"center"}>Please check back later</Text>
						</Stack>
					</Paper>
					<Group
						position={"center"}
						py={"4rem"}
					>
						<Link href={"https://check5.com.au"}>
							<Image
								src={theme.isDark ? "/logo-dark.png" : "/logo-light.png"}
								alt={"logo"}
								height={60}
								width={200}
								style={{ width: "auto" }}
							/>
						</Link>
					</Group>
				</Stack>
			</Center>
		</Stack>
	);
}