import { IBaseItem, ParseBaseItem } from "../helpers/helpers";

export interface ITeam extends IBaseItem {
	id?: number;
	companyId?: number;
	name: string;
}

export class Team {
	static Parse(item: ITeam) {
		return ParseBaseItem(item);
	}
}