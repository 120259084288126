import { IFetch } from "./fetch.hooks";
import { IAdminRequests } from "../interfaces/apiRequests";
import { Company, ICompany } from "../models/company.model";
import { INewCompany, ITenant, ITenantInit } from "../models/tenant.model";
import { Employee, IEmployee } from "../models/employee.model";

export function useAdminRequests(http: IFetch): IAdminRequests {

	async function GetCurrentTenant(): Promise<ITenant> {
		return await http.Get<ITenant>(`admin/current`);
	}

	async function GetTenants(): Promise<ITenant[]> {
		return await http.Get<ITenant[]>(`admin/tenants`);
	}

	async function SwitchTenant(tenantId: number): Promise<ITenant> {
		return await http.Put<ITenant, ITenantInit>(`admin/switch?tenantId=${tenantId}`);
	}

	async function InitialiseTenant(item: ITenantInit): Promise<ITenant> {
		return await http.Post<ITenant, ITenantInit>(`admin/initialise`, item);
	}

	async function CreateNewCompany(item: INewCompany): Promise<ICompany> {
		const json = await http.Post<ICompany, INewCompany>(`admin/company`, item);
		return Company.Parse(json);
	}

	async function UpdateSurveySchedule(item: ICompany): Promise<ICompany> {
		const json = await http.Put<ICompany>(`admin/schedule`, item);
		return Company.Parse(json);
	}

	async function ImportEmployees(employees: IEmployee[]): Promise<IEmployee[]> {
		const json = await http.Post<IEmployee[]>(`admin/import`, employees);
		return json.map(i => Employee.Parse(i));
	}

	return {
		GetCurrentTenant,
		GetTenants,
		SwitchTenant,
		InitialiseTenant,
		CreateNewCompany,
		UpdateSurveySchedule,
		ImportEmployees
	};
}