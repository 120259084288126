import { createContext, PropsWithChildren, useContext } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";

export interface IThemeProviderProps {
	storageKey: string;
}

export interface IThemeContext {
	isDark: boolean;

	toggleColorScheme(value?: ColorScheme): void;
}

const ThemeContext = createContext<IThemeContext>(
	{...{} as IThemeContext}
);

export function ThemeProvider(props: PropsWithChildren<IThemeProviderProps>): JSX.Element {
	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: props.storageKey,
		defaultValue: "dark",
		getInitialValueInEffect: true,
	});

	function toggleColorScheme(value?: ColorScheme) {
		setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
	}

	return (
		<ThemeContext.Provider
			value={{
				isDark: colorScheme === "dark",
				toggleColorScheme
			}}
		>
			<ColorSchemeProvider
				colorScheme={colorScheme}
				toggleColorScheme={toggleColorScheme}
			>
				<MantineProvider
					withGlobalStyles
					withNormalizeCSS
					theme={{
						colorScheme: colorScheme,
						primaryColor: "cyan"
					}}
				>
					{props.children}
				</MantineProvider>
			</ColorSchemeProvider>
		</ThemeContext.Provider>
	);
}

export const useTheme = (): IThemeContext => useContext(ThemeContext);