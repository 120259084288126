import { IBaseItem, ParseBaseItem } from "../helpers/helpers";

export interface INetworkPartner extends IBaseItem {
	id?: number;
	name: string;
	logo?: string;
	logoFile?: File;
	url?: string;
	signUpUrl?: string;
	email?: string;
	phone?: string;
	description?: string;
	category1?: boolean;
	category2?: boolean;
	category3?: boolean;
	category4?: boolean;
	category5?: boolean;
	status: number;
}

export class NetworkPartner {
	static Parse(item: INetworkPartner) {
		return ParseBaseItem(item);
	}
}