import { Role } from "./roles.enum";
import { IBaseItem, ParseBaseItem } from "../helpers/helpers";

export interface IAdminUser extends IBaseItem {
	id?: string;
	firstName: string;
	lastName: string;
	email: string;
	roleId: Role;
	roleName?: string;
}

export class AdminUser {
	static Parse(item: IAdminUser) {
		return ParseBaseItem(item);
	}
}