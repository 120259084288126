import { IFetch } from "./fetch.hooks";
import { ICheckInRequests } from "../interfaces/apiRequests";
import { ICheckInResponse } from "../models/checkInResponse.model";

export function useCheckInRequests(http: IFetch): ICheckInRequests {

	async function GetById(id: string): Promise<ICheckInResponse> {
		return await http.Get<ICheckInResponse>(`checkin?id=${id}`);
	}

	async function SubmitResponse(item: ICheckInResponse): Promise<ICheckInResponse> {
		return await http.Post<ICheckInResponse>(`checkin`, item);
	}

	return {
		GetById,
		SubmitResponse
	};
}