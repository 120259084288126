import { IBaseItem, ParseBaseItem } from "../helpers/helpers";
import { IEmployee, Employee } from "./employee.model";
import { CheckInResponse, SurveyStatus } from "./surveyStatus.enum";
import { SmsStatus } from "./smsErrors.model";

export interface ISurvey extends IBaseItem {
	id?: number;
	employeeId: number;
	employee?: IEmployee;
	companyId: number;
	status: SurveyStatus;
	response1?: CheckInResponse;
	response2?: CheckInResponse;
	response3?: CheckInResponse;
	response4?: CheckInResponse;
	response5?: CheckInResponse;
	supportRequested?: boolean;
	assignedUser?: string;
	viewedDate?: Date;
	responseDate?: Date;
	actionedDate?: Date;
	smsId?: string;
	smsStatusCode?: SmsStatus;
	smsStatus?: string;
	referral1?: boolean;
	referral2?: boolean;
	referral3?: boolean;
	referral4?: boolean;
	referral5?: boolean;
	noteCount?: number;
}

export class Survey {
	static Parse(item: ISurvey) {
		item = ParseBaseItem(item);
		item.viewedDate = item.viewedDate ? new Date(item.viewedDate) : undefined;
		item.responseDate = item.responseDate ? new Date(item.responseDate) : undefined;
		item.actionedDate = item.actionedDate ? new Date(item.actionedDate) : undefined;
		item.employee = item.employee ? Employee.Parse(item.employee) : undefined;
		return item;
	}
}