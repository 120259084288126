import { format } from "date-fns";
import { enAU } from "date-fns/locale";
import { ISurvey } from "../models/survey.model";
import { INetworkPartnersFilter, ISurveysFilter } from "../models/filter.model";
import { Config } from "./config";
import { INetworkPartner } from "../models/networkPartner.model";
import { IEmployee } from "../models/employee.model";
import { SelectItem } from "@mantine/core";
import { Role, RoleNames } from "../models/roles.enum";

export const DEFAULT_FILTER: ISurveysFilter = {
	page: 1,
	pageSize: 25,
	orderBy: "id",
	descending: true,
};

export const ResponseCategoryProps: (keyof ISurvey)[] = ["response1", "response2", "response3", "response4", "response5"];
export const ReferralCategoryProps: (keyof ISurvey)[] = ["referral1", "referral2", "referral3", "referral4", "referral5"];
export const NetworkPartnerCategoryProps: (keyof INetworkPartner)[] = ["category1", "category2", "category3", "category4", "category5"];

export interface IBaseItem {
	createdDate?: Date;
	modifiedDate?: Date;
	createdBy?: string;
	modifiedBy?: string;
}

export function ParseBaseItem<TItem extends IBaseItem>(item: TItem) {
	return {
		...item,
		createdDate: item.createdDate ? new Date(item.createdDate) : undefined,
		modifiedDate: item.modifiedDate ? new Date(item.modifiedDate) : undefined,
	} as TItem;
}

export function GetFormattedDate(date?: Date, formatType: string = "P", locale: Locale = enAU): string {
	if (!date) return "";
	return format(date, formatType, { locale: locale });
}

export function GetNetworkPartnerLogoUrl(networkPartnerId?: number, logo?: string, companyId?: number | "gnp") {
	if (!networkPartnerId || !logo || !companyId) return null;
	return `${Config.apiUri}image/${companyId}/${networkPartnerId}/${logo}`;
}

export function GetGlobalNetworkPartnerFormData(gnp: INetworkPartner): FormData {
	const formData = new FormData();
	Object.keys(gnp).forEach(key => {
		if (key === "logoFile") {
			return;
		}
		if (key === "createdDate" || key === "modifiedDate") {
			formData.append(key, (gnp[key as keyof INetworkPartner] as Date).toISOString());
		} else {
			formData.append(key, gnp[key as keyof INetworkPartner] as string);
		}
	});
	if (gnp.logoFile) {
		formData.append("logoFile", gnp.logoFile, gnp.logoFile.name);
	}
	return formData;
}

export function GetNetworkPartnerEmailContent(networkPartner?: INetworkPartner, employee?: IEmployee) {
	if (!networkPartner) {
		return "";
	}
	const mailto = `mailto:${employee?.email ?? ""}`;
	const subject = `?subject=` + encodeURIComponent(`Check5 Support Request - Network Partner Information - ${networkPartner.name}`);
	const body = `&body=` +
		encodeURIComponent(
			`Hi${employee?.firstName ? " " + employee.firstName : ""},\n` +
			`\n` +
			`Here is some Network Partner Information from Check5:\n` +
			`\n` +
			`Name: ${networkPartner.name}\n` +
			`Email: ${networkPartner.email ?? "none"}\n` +
			`Phone: ${networkPartner.phone ?? "none"}\n` +
			`Web: ${networkPartner.url ?? "none"}\n` +
			`Sign up link: ${networkPartner.signUpUrl ?? "none"}\n` +
			`\n` +
			`Description: `,
		);

	let content = mailto + subject + body;
	const description = encodeURIComponent(`${networkPartner.description ?? "none"}`);
	if ((content.length + description.length) < 2000) {
		content += description;
	} else {
		content += `Content too long, please copy and paste`;
	}

	return content;
}

export function FilterNetworkPartners(filter: INetworkPartnersFilter, items?: INetworkPartner[]) {
	const search = filter.search?.toLowerCase() ?? "";
	return items?.filter(i =>
		(
			!search ||
			i.name.toLowerCase().includes(search) ||
			i.email?.toLowerCase().includes(search) ||
			i.url?.toLowerCase().includes(search) ||
			i.phone?.toLowerCase().includes(search)
		)
		&&
		(
			// if none are selected then show all
			(
				!filter.category1 &&
				!filter.category2 &&
				!filter.category3 &&
				!filter.category4 &&
				!filter.category5
			) ||
			// otherwise show where contains a selected category
			(i.category1 && i.category1 === filter.category1) ||
			(i.category2 && i.category2 === filter.category2) ||
			(i.category3 && i.category3 === filter.category3) ||
			(i.category4 && i.category4 === filter.category4) ||
			(i.category5 && i.category5 === filter.category5)
		),
	) ?? [];
}

export const DEFAULT_NETWORK_PARTNERS_FILTER: INetworkPartnersFilter = {
	search: "",
	category1: false,
	category2: false,
	category3: false,
	category4: false,
	category5: false,
};

export const AllowedRoles = [
	{
		value: Role.CompanyAdmin.toString(),
		label: RoleNames[Role.CompanyAdmin],
	},
	{
		value: Role.ReportingUser.toString(),
		label: RoleNames[Role.ReportingUser],
	},
	{
		value: Role.WellbeingTeam.toString(),
		label: RoleNames[Role.WellbeingTeam],
	},
	{
		value: Role.UserAdmin.toString(),
		label: RoleNames[Role.UserAdmin],
	},
] as SelectItem[];