import { createContext, PropsWithChildren, useContext } from "react";
import { useFetch } from "../hooks/fetch.hooks";
import { useTenant } from "../hooks/tenant.hook";
import { useAuth } from "../hooks/auth.hook";
import {
	IAdminUsersRequests,
	IEmployeesRequests,
	INoteRequests,
	IReportingRequests,
	IAdminRequests,
	ISurveyRequests,
	ITeamRequests,
	IGlobalNetworkPartnerRequests,
	ICompanyNetworkPartnerRequests,
	INetworkPartnerExclusionRequests,
} from "../interfaces/apiRequests";
import { useAdminUserRequests } from "../hooks/adminUserRequests.hooks";
import { ICompany } from "../models/company.model";
import { useTeamRequests } from "../hooks/teamRequests.hooks";
import { useEmployeesRequests } from "../hooks/employeesRequests.hooks";
import ApiError, { IApiError } from "../interfaces/apiError";
import { showNotification } from "@mantine/notifications";
import { useSurveyRequests } from "../hooks/surveyRequests.hooks";
import { useNoteRequests } from "../hooks/noteRequests.hooks";
import { IAdminUser } from "../models/adminUser.model";
import { useReportingRequests } from "../hooks/reportingRequests.hooks";
import { useAdminRequests } from "../hooks/adminRequests.hooks";
import { useGlobalNetworkPartnerRequests } from "../hooks/globalNetworkPartnerRequests.hooks";
import { useCompanyNetworkPartnerRequests } from "../hooks/companyNetworkPartnerRequests.hooks";
import { useNetworkPartnerExclusionRequests } from "../hooks/networkPartnerExclusionRequests.hooks";

export interface IApiContext {
	fetching: boolean;
	user?: IAdminUser;
	company?: ICompany;
	companies: ICompany[];

	changeCompany(company: ICompany): void;

	adminUsers: IAdminUsersRequests;
	employees: IEmployeesRequests;
	notes: INoteRequests;
	surveys: ISurveyRequests;
	teams: ITeamRequests;
	reporting: IReportingRequests;
	globalNetworkPartners: IGlobalNetworkPartnerRequests;
	companyNetworkPartners: ICompanyNetworkPartnerRequests;
	exclusions: INetworkPartnerExclusionRequests;
	admin: IAdminRequests;
}

export const ApiContext = createContext<IApiContext>(
	{ ...{} as IApiContext },
);

export function onError(error: IApiError): void {
	const apiError = new ApiError(error);
	console.log(error, apiError);
	showNotification({
		title: apiError.title,
		message: apiError.message,
		color: "red",
	});
}

export function ApiProvider(props: PropsWithChildren): JSX.Element {
	const token = useAuth();
	const tenant = useTenant(token);
	const http = useFetch(token, tenant.company?.id);

	const adminUsers = useAdminUserRequests(http, token, tenant.company);
	const employees = useEmployeesRequests(http);
	const notes = useNoteRequests(http);
	const surveys = useSurveyRequests(http);
	const teams = useTeamRequests(http);
	const reporting = useReportingRequests(http);
	const globalNetworkPartners = useGlobalNetworkPartnerRequests(http);
	const companyNetworkPartners = useCompanyNetworkPartnerRequests(http);
	const exclusions = useNetworkPartnerExclusionRequests(http);
	const admin = useAdminRequests(http);

	return (
		<ApiContext.Provider
			value={{
				fetching: http.fetching,
				user: adminUsers.currentUser,
				company: tenant.company,
				companies: tenant.companies,
				changeCompany: tenant.setSelectedCompany,
				adminUsers,
				employees,
				notes,
				surveys,
				teams,
				reporting,
				globalNetworkPartners,
				companyNetworkPartners,
				exclusions,
				admin,
			}}
		>
			{props.children}
		</ApiContext.Provider>
	);
}

export const useApi = (): IApiContext => useContext(ApiContext);