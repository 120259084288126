import Head from "next/head";
import { Config } from "../../helpers/config";

export interface ISeoProps {
	title: string;
	description: string;
}

export default function Seo(props: ISeoProps) {
	return (
		<Head>
			<title>{`${Config.title} | ${props.title}`}</title>
			<meta name={"description"} content={props.description}/>
			<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width"/>
			<link rel={"icon"} href={"/favicon.ico"}/>
		</Head>
	);
}