import { IFetch } from "./fetch.hooks";
import { IEmployeesRequests } from "../interfaces/apiRequests";
import { IEmployee, Employee } from "../models/employee.model";
import { IEmployeeCap} from "../models/employeeCap.model";

export function useEmployeesRequests(http: IFetch): IEmployeesRequests {

	async function Create(item: IEmployee): Promise<IEmployee> {
		const json = await http.Post<IEmployee>(`employees`, item);
		return Employee.Parse(json);
	}

	async function Get(teamId?: number): Promise<IEmployee[]> {
		const teamQuery = teamId ? `?teamId=${teamId}` : "";
		const json = await http.Get<IEmployee[]>(`employees${teamQuery}`);
		return json.map(i => Employee.Parse(i));
	}

	async function GetById(id: number): Promise<IEmployee> {
		const json = await http.Get<IEmployee>(`employees/${id}`);
		return Employee.Parse(json);
	}

	async function Update(item: IEmployee): Promise<IEmployee> {
		const json = await http.Put<IEmployee>(`employees`, item);
		return Employee.Parse(json);
	}

	async function Delete(id: number): Promise<IEmployee> {
		const json = await http.Delete<IEmployee>(`employees?employeeId=${id}`);
		return Employee.Parse(json);
	}

	async function GetCompanyEmployeeCap(): Promise<IEmployeeCap> {
		return await http.Get<IEmployeeCap>(`employees/cap`);
	}

	return {
		Create,
		Get,
		GetById,
		Update,
		Delete,
		GetCompanyEmployeeCap
	};
}