import { IBaseItem, ParseBaseItem } from "../helpers/helpers";

export interface INote extends IBaseItem {
	id?: number;
	surveyId: number;
	text: string;
}

export class Note {
	static Parse(item: INote) {
		return ParseBaseItem(item);
	}
}