import { createContext, PropsWithChildren, useContext } from "react";
import { useFetch } from "../hooks/fetch.hooks";
import { useCheckInRequests } from "../hooks/checkInRequests.hooks";
import { ICheckInRequests } from "../interfaces/apiRequests";

export interface ICheckInApiContext {
	fetching: boolean;
	checkIn: ICheckInRequests;
}

export const CheckInApiContext = createContext<ICheckInApiContext>(
	{...{} as ICheckInApiContext}
);

export function CheckInApiProvider(props: PropsWithChildren): JSX.Element {
	const http = useFetch();

	const checkIn = useCheckInRequests(http);

	return (
		<CheckInApiContext.Provider
			value={{
				fetching: http.fetching,
				checkIn
			}}
		>
			{props.children}
		</CheckInApiContext.Provider>
	);
}

export const useCheckInApi = (): ICheckInApiContext => useContext(CheckInApiContext);