import { IFetch } from "./fetch.hooks";
import { ITeamRequests } from "../interfaces/apiRequests";
import { ITeam, Team } from "../models/team.model";

export function useTeamRequests(http: IFetch): ITeamRequests {

	async function Create(item: ITeam): Promise<ITeam> {
		const json = await http.Post<ITeam>(`teams`, item);
		return Team.Parse(json);
	}

	async function Get(): Promise<ITeam[]> {
		const json = await http.Get<ITeam[]>(`teams`);
		return json.map(i => Team.Parse(i));
	}

	async function GetById(id: number): Promise<ITeam> {
		const json = await http.Get<ITeam>(`teams/${id}`);
		return Team.Parse(json);
	}

	async function Update(item: ITeam): Promise<ITeam> {
		const json = await http.Put<ITeam>(`teams`, item);
		return Team.Parse(json);
	}

	async function Delete(id: number): Promise<ITeam> {
		const json = await http.Delete<ITeam>(`teams?teamId=${id}`);
		return Team.Parse(json);
	}

	return {
		Create,
		Get,
		GetById,
		Update,
		Delete
	};
}