import { IBaseItem, ParseBaseItem } from "../helpers/helpers";

export interface IEmployee extends IBaseItem {
	id?: number;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	companyId?: number;
	teamId?: number;
	teamName?: string;
}

export class Employee {
	static Parse(item: IEmployee) {
		return ParseBaseItem(item);
	}
}