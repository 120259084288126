import { IFetch } from "./fetch.hooks";
import { IGlobalNetworkPartnerRequests } from "../interfaces/apiRequests";
import { GetGlobalNetworkPartnerFormData } from "../helpers/helpers";
import { INetworkPartner, NetworkPartner } from "../models/networkPartner.model";

export function useGlobalNetworkPartnerRequests(http: IFetch): IGlobalNetworkPartnerRequests {
	async function Create(item: INetworkPartner): Promise<INetworkPartner> {
		const formData = GetGlobalNetworkPartnerFormData(item);
		const json = await http.Post<INetworkPartner, FormData>(`globalNetworkPartners`, formData);
		return NetworkPartner.Parse(json);
	}

	async function Get(): Promise<INetworkPartner[]> {
		const json = await http.Get<INetworkPartner[]>(`globalNetworkPartners`);
		return json.map(i => NetworkPartner.Parse(i));
	}

	async function GetById(id: number): Promise<INetworkPartner> {
		const json = await http.Get<INetworkPartner>(`globalNetworkPartners/${id}`);
		return NetworkPartner.Parse(json);
	}

	async function Update(item: INetworkPartner): Promise<INetworkPartner> {
		const formData = GetGlobalNetworkPartnerFormData(item);
		const json = await http.Put<INetworkPartner, FormData>(`globalNetworkPartners`, formData);
		return NetworkPartner.Parse(json);
	}

	async function Delete(id: number): Promise<INetworkPartner> {
		const json = await http.Delete<INetworkPartner>(`globalNetworkPartners?id=${id}`);
		return NetworkPartner.Parse(json);
	}

	return {
		Create,
		Get,
		GetById,
		Update,
		Delete,
	};
}