import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/nextjs";

/**
 * Forces authentication if user not signed in and returns token for api calls
 */
export function useAuth(): string | undefined {
	const [token, setToken] = useState<string>();

	const auth0 = useAuth0();

	useEffect(() => {
		async function getToken() {
			const accessToken = await auth0.getAccessTokenSilently({
				authorizationParams: {
					audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
					scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
				}
			});
			setToken(accessToken);
		}

		if (!auth0.isLoading && auth0.isAuthenticated) {
			Sentry.setUser({...auth0.user});
			void getToken();
		}

		if (!auth0.isLoading && !auth0.isAuthenticated) {
			localStorage.setItem("redirect", window.location.href);
			void auth0.loginWithRedirect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth0.isLoading, auth0.isAuthenticated, auth0.user, auth0.error]);

	return token;
}