import "../styles/globals.css";
import type { AppProps } from "next/app";
import { Auth0Provider } from "@auth0/auth0-react";
import { Notifications } from "@mantine/notifications";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ApiProvider, onError } from "../context/api.context";
import { ThemeProvider } from "../context/theme.context";
import { StorageKeys } from "../helpers/config";
import { useRouter } from "next/router";
import { CheckInApiProvider } from "../context/checkin.context";
import { AppProvider } from "../context/app.context";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/en-au";
import { IApiError } from "../interfaces/apiError";
import { Analytics } from "@vercel/analytics/react";
import Offline from "./offline";

dayjs.extend(LocalizedFormat);
dayjs.locale("en-au");

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			onError: (e: unknown) => onError(e as IApiError),
		},
		mutations: {
			onError: (e: unknown) => onError(e as IApiError),
		},
	},
});

export default function App({
	Component,
	pageProps,
}: AppProps) {
	const router = useRouter();

	async function onRedirectCallback() {
		const redirect = localStorage.getItem("redirect");
		await router.replace(redirect || "/");
		localStorage.removeItem("redirect");
	}

	if (process.env.NEXT_PUBLIC_OFFLINE) {
		return (
			<ThemeProvider storageKey={StorageKeys.colorScheme}>
				<Offline/>
				<Analytics/>
			</ThemeProvider>
		);
	}

	if (router.pathname === "/checkin") {
		return (
			<ThemeProvider storageKey={StorageKeys.colorScheme}>
				<Notifications/>
				<QueryClientProvider client={queryClient}>
					<CheckInApiProvider>
						<Component {...pageProps} />
						<Analytics/>
					</CheckInApiProvider>
				</QueryClientProvider>
			</ThemeProvider>
		);
	}

	return (
		<ThemeProvider storageKey={StorageKeys.colorScheme}>
			<Notifications/>
			<Auth0Provider
				domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
				clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
				onRedirectCallback={onRedirectCallback}
				authorizationParams={{
					redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
					scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE || "",
					audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
				}}
			>
				<QueryClientProvider client={queryClient}>
					<ApiProvider>
						<AppProvider>
							<Component {...pageProps} />
							<Analytics/>
						</AppProvider>
					</ApiProvider>
				</QueryClientProvider>
			</Auth0Provider>
		</ThemeProvider>
	);
}
