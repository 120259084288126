import { IFetch } from "./fetch.hooks";
import { INetworkPartnerExclusionRequests } from "../interfaces/apiRequests";

export function useNetworkPartnerExclusionRequests(http: IFetch): INetworkPartnerExclusionRequests {
	async function Create(id: number): Promise<void> {
		return await http.Post<void>(`networkPartnerExclusions?gnpId=${id}`);
	}

	async function Get(): Promise<number[]> {
		return await http.Get<number[]>(`networkPartnerExclusions`);
	}

	async function Delete(id: number): Promise<void> {
		await http.Delete<void>(`networkPartnerExclusions?gnpId=${id}`);
	}

	return {
		Create,
		Get,
		Delete,
	};
}