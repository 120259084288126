export enum Role {
	CompanyAdmin = 1,
	TeamAdmin, // NOTE: currently not used
	ReportingUser,
	GlobalAdmin,
	Check5Admin,
	WellbeingTeam,
	UserAdmin
}

export const RoleNames: Record<Role, string> = {
	[Role.CompanyAdmin]: "Company Admin",
	[Role.TeamAdmin]: "Team Admin",
	[Role.ReportingUser]: "Reporting User",
	[Role.GlobalAdmin]: "Global Admin",
	[Role.Check5Admin]: "Check5 Admin",
	[Role.WellbeingTeam]: "Wellbeing Team",
	[Role.UserAdmin]: "User Admin"
};