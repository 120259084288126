import { Config } from "../helpers/config";
import { useState } from "react";

type FetchFunction = <T>(endpoint: string, fileName?: string) => Promise<T>;
type FetchFunctionData = <T, TData = T>(endpoint: string, data?: TData, fileName?: string) => Promise<T>;
type FetchFunctionMethod = <T, TData = T>(method: HttpMethod, endpoint: string, data?: TData, fileName?: string) => Promise<T | void>;

export interface IFetch {
	fetching: boolean;
	Fetch: FetchFunctionMethod;
	Get: FetchFunction;
	Post: FetchFunctionData;
	Put: FetchFunctionData;
	Patch: FetchFunctionData;
	Delete: FetchFunctionData
}

export enum HttpMethod {
	HEAD = "HEAD",
	OPTIONS = "OPTIONS",
	GET = "GET",
	PUT = "PUT",
	PATCH = "PATCH",
	POST = "POST",
	DELETE = "DELETE",
}

export function useFetch(token?: string, companyId?: number): IFetch {
	const [fetching, setFetching] = useState(false);

	async function Fetch<T, TData = T>(method: HttpMethod, endpoint: string, data?: TData, fileName?: string): Promise<T | void> {
		setFetching(true);
		const isFormData = data instanceof FormData;
		const body = data ?
			isFormData
				? data
				: JSON.stringify(data)
			: undefined;

		const headers = new Headers();
		if (isFormData || !!fileName) {
			headers.append("Accept", `*/*`);
		} else {
			headers.append("Accept", `application/json`);
			headers.append("Content-Type", `application/json`);
		}

		if (token) headers.append("Authorization", `Bearer ${token}`);
		if (companyId) headers.append("CompanyId", companyId.toString());

		return await fetch(Config.apiUri + endpoint, {
			method,
			body,
			headers
		})
			.then(async (response) => {
				if (response.ok) {
					if (fileName) {
						const blob = await response.blob();
						await DownloadFile(blob, fileName);
						return;
					} else {
						const text = await response.text();
						if (response.headers.get("Content-Type")?.startsWith("text/plain")) return text as unknown as T;
						return (text ? JSON.parse(text) : text) as T;
					}
				} else {
					const error = await response.json();
					console.log(error)
					throw error;
				}
			})
			.catch(async (error) => {
				throw error;
			})
			.finally(() => setFetching(false));
	}

	const Get: FetchFunction = async <T, >(endpoint: string, fileName?: string) =>
		await Fetch<T>(HttpMethod.GET, endpoint, undefined, fileName) as T;
	const Post: FetchFunctionData = async <T, TData = T>(endpoint: string, data?: TData) =>
		await Fetch<T, TData>(HttpMethod.POST, endpoint, data) as T;
	const Put: FetchFunctionData = async <T, TData = T>(endpoint: string, data?: TData) =>
		await Fetch<T, TData>(HttpMethod.PUT, endpoint, data) as T;
	const Patch: FetchFunctionData = async <T, TData = T>(endpoint: string, data?: TData) =>
		await Fetch<T, TData>(HttpMethod.PATCH, endpoint, data) as T;
	const Delete: FetchFunctionData = async <T, TData = T>(endpoint: string, data?: TData) =>
		await Fetch<T, TData>(HttpMethod.DELETE, endpoint, data) as T;

	return {fetching, Fetch, Get, Post, Put, Patch, Delete};
}

async function DownloadFile(blob: Blob, fileName: string): Promise<void> {
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	if (blob.type !== "application/pdf" && !blob.type.startsWith("image/")) {
		a.download = fileName;
	}
	a.target = "_blank";
	a.dataset.interception = "off";
	document.body.appendChild(a);
	a.click();
	a.remove();
	URL.revokeObjectURL(url);
}