import { IFetch } from "./fetch.hooks";
import { INoteRequests } from "../interfaces/apiRequests";
import { INote, Note } from "../models/note.model";

export function useNoteRequests(http: IFetch): INoteRequests {

	async function Create(item: INote): Promise<INote> {
		const json = await http.Post<INote>(`notes`, item);
		return Note.Parse(json);
	}

	async function Get(surveyId: number): Promise<INote[]> {
		const json = await http.Get<INote[]>(`notes?surveyId=${surveyId}`);
		return json.map(i => Note.Parse(i));
	}

	async function GetById(id: number): Promise<INote> {
		const json = await http.Get<INote>(`notes/${id}`);
		return Note.Parse(json);
	}

	async function Update(item: INote): Promise<INote> {
		const json = await http.Put<INote>(`notes`, item);
		return Note.Parse(json);
	}

	async function Delete(id: number): Promise<INote> {
		const json = await http.Delete<INote>(`notes?noteId=${id}`);
		return Note.Parse(json);
	}

	return {
		Create,
		Get,
		GetById,
		Update,
		Delete
	};
}