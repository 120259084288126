export interface IApiError {
	error?: string;
	errors?: {[key:string]: string[]};
	title?: string;
	detail?: string;
	message?: string;
}

export default class ApiError {
	public title: string = "Error";
	public message: string = "An error occurred";

	public constructor(item?: IApiError) {
		if (!item) return this;

		this.title = item.title ? item.title : this.title;
		this.message = item.message ? item.message : this.message;

		if (item.errors) {
			const keys = Object.keys(item.errors);
			const messages = keys.map(i=>
				`${i}: ${item.errors![i].join(" ")}`
			);
			this.message = messages.join("\n");
		} else {
			this.message = item.error ? item.error : this.message;
		}
		console.log(this)
	}
}