import { IFetch } from "./fetch.hooks";
import { IReportingRequests } from "../interfaces/apiRequests";
import { IReportData } from "../models/reporting.model";
import { GetFormattedDate } from "../helpers/helpers";
import { ITeam } from "../models/team.model";

export function useReportingRequests(http: IFetch): IReportingRequests {

	async function GetReport(startDate: Date, endDate: Date, team: ITeam | null): Promise<IReportData> {
		const start = GetFormattedDate(startDate, "yyyy-MM-dd");
		const end = GetFormattedDate(endDate, "yyyy-MM-dd");
		const teamId = team ? `&teamId=${team.id}` : "";
		return await http.Get<IReportData>(`reporting?start=${start}&end=${end}${teamId}`);
	}

	return {
		GetReport
	};
}