import { IFetch } from "./fetch.hooks";
import { IAdminUsersRequests } from "../interfaces/apiRequests";
import { AdminUser, IAdminUser } from "../models/adminUser.model";
import { useQuery } from "@tanstack/react-query";
import ApiError from "../interfaces/apiError";
import { ICompany } from "../models/company.model";
import { useMemo } from "react";
import { SelectItem } from "@mantine/core";

export function useAdminUserRequests(http: IFetch, token?: string, company?: ICompany): IAdminUsersRequests {
	async function Create(item: IAdminUser): Promise<IAdminUser> {
		const json = await http.Post<IAdminUser>(`adminusers`, item);
		return AdminUser.Parse(json);
	}

	async function GetCurrentUser(): Promise<IAdminUser> {
		const json = await http.Get<IAdminUser>(`adminusers/current`);
		return AdminUser.Parse(json);
	}

	async function Get(): Promise<IAdminUser[]> {
		const json = await http.Get<IAdminUser[]>(`adminusers`);
		return json.map(i => AdminUser.Parse(i));
	}

	async function GetById(id: string): Promise<IAdminUser> {
		const json = await http.Get<IAdminUser>(`adminusers/${id}`);
		return AdminUser.Parse(json);
	}

	async function Update(item: IAdminUser): Promise<IAdminUser> {
		const json = await http.Put<IAdminUser>(`adminusers`, item);
		return AdminUser.Parse(json);
	}

	async function Delete(id: string): Promise<IAdminUser> {
		const json = await http.Delete<IAdminUser>(`adminusers?userId=${id}`);
		return AdminUser.Parse(json);
	}

	const userQuery = useQuery<IAdminUser, ApiError>(
		["GetCurrentUser", company, token],
		GetCurrentUser,
		{
			enabled: !!company && !!token
		}
	);

	const usersQuery = useQuery<IAdminUser[], ApiError>(
		["adminUsers.Get", company, token],
		Get,
		{
			placeholderData: [],
			enabled: !!company && !!token
		}
	);

	const selectItems = useMemo(() => {
		if (!usersQuery.data) return [];
		return usersQuery.data.map(i => ({
			label: `${i.firstName} ${i.lastName}`,
			value: i.id
		} as SelectItem));
	}, [usersQuery.data]);

	function GetUserName(userId?: string) {
		if (!userId) return "";
		const user = usersQuery.data?.find(i => i.id === userId);
		return `${user?.firstName ?? ""}${user?.lastName ? " " : ""}${user?.lastName ?? ""}`;
	}

	return {
		loading: userQuery.isFetching || userQuery.isLoading,
		currentUser: userQuery.data,
		items: usersQuery.data || [],
		selectItems,
		usersLoading: usersQuery.isFetching || usersQuery.isLoading,
		GetUserName,
		Create,
		GetById,
		Update,
		Delete
	};
}