import { Role } from "../models/roles.enum";
import { IPermissions, PermissionsDefault } from "../models/permissions.model";
import { useMemo } from "react";

export function usePermissions(role?: Role): IPermissions {
	return useMemo(() => {
		switch (role) {
			case Role.GlobalAdmin:
				return {
					isGlobalAdmin: true, // allow GA access
					canManageGlobalNetworkPartners: true, // allow global network partner crud
					canReadCompany: true,
					canManageCompany: true,
					canEditCompany: true,
					canReadSurveys: true,
					canEditSurveys: true,
					canReadEmployeeData: true,
					canEditEmployeeData: true,
					showReportAsDashboard: false,
				};
			case Role.Check5Admin:
				return {
					...PermissionsDefault,
					canManageGlobalNetworkPartners: true,
					canReadCompany: true,
					canManageCompany: true,
					canReadSurveys: true,
					canEditSurveys: true,
					canReadEmployeeData: true,
					canEditEmployeeData: true,
				};
			case Role.CompanyAdmin:
				return {
					...PermissionsDefault,
					canReadCompany: true,
					canManageCompany: true,
					canReadSurveys: true,
					canEditSurveys: true,
					canReadEmployeeData: true,
					canEditEmployeeData: true,
				};
			case Role.WellbeingTeam:
				return {
					...PermissionsDefault,
					canReadCompany: true,
					canReadSurveys: true,
					canEditSurveys: true,
					canReadEmployeeData: true,
				};
			case Role.UserAdmin:
				return {
					...PermissionsDefault,
					canReadCompany: true,
					canReadEmployeeData: true,
					canEditEmployeeData: true,
					showReportAsDashboard: true,
				};
			case Role.ReportingUser:
				return {
					...PermissionsDefault,
					showReportAsDashboard: true,
				};
			case Role.TeamAdmin:
			default:
				return PermissionsDefault;
		}
	}, [role]);
}