export interface ICompany {
	id: number;
	name: string;
	scheduleDayOfWeek?: DayOfWeek;
	scheduleLastRunDate?: Date;
	scheduleNextRunDate?: Date;
	scheduleRecurrence?: ScheduleRecurrence;
	scheduleTimeOfDay?: number;
	employeeCap?: number;
}

export class Company {
	static Parse(item: ICompany) {
		return {
			...item,
			scheduleLastRunDate: item.scheduleLastRunDate ? new Date(item.scheduleLastRunDate) : undefined,
			scheduleNextRunDate: item.scheduleNextRunDate ? new Date(item.scheduleNextRunDate) : undefined,
		} as ICompany;
	}
}

export enum DayOfWeek {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday
}

export const Weekdays: Record<DayOfWeek, string> = {
	[DayOfWeek.Sunday]: "Sunday",
	[DayOfWeek.Monday]: "Monday",
	[DayOfWeek.Tuesday]: "Tuesday",
	[DayOfWeek.Wednesday]: "Wednesday",
	[DayOfWeek.Thursday]: "Thursday",
	[DayOfWeek.Friday]: "Friday",
	[DayOfWeek.Saturday]: "Saturday",
};

export enum ScheduleRecurrence {
	Monthly = 30,
	BiMonthly = 60,
	Quarterly = 90,
	SixMonthly = 180,
	Yearly = 365
}

export const Recurrence: Record<ScheduleRecurrence, string> = {
	[ScheduleRecurrence.Monthly]: "Monthly",
	[ScheduleRecurrence.BiMonthly]: "Bi-Monthly",
	[ScheduleRecurrence.Quarterly]: "Quarterly",
	[ScheduleRecurrence.SixMonthly]: "6 Monthly",
	[ScheduleRecurrence.Yearly]: "Yearly",
};