import { useFetch } from "./fetch.hooks";
import { Company, ICompany } from "../models/company.model";
import { useQuery } from "@tanstack/react-query";
import ApiError from "../interfaces/apiError";
import { useLocalStorage } from "@mantine/hooks";
import { StorageKeys } from "../helpers/config";
import { useMemo } from "react";

export interface ITenantHook {
	companies: ICompany[];
	company?: ICompany;

	setSelectedCompany(company: ICompany): void;
}

export function useTenant(token?: string): ITenantHook {
	const http = useFetch(token);
	const [selectedCompany, setSelectedCompany] = useLocalStorage<ICompany>({
		key: StorageKeys.selectedCompany,
		defaultValue: undefined,
		getInitialValueInEffect: true
	});

	async function GetCompanies() {
		const json = await http.Get<ICompany[]>("companies");
		return json.map(i => Company.Parse(i));
	}

	function checkInitialCompany(items: ICompany[]) {
		const company = items.find(i => i.id === selectedCompany?.id);
		if (!company && items.length > 0) {
			setSelectedCompany(items[0]);
		}
	}

	const companiesQuery = useQuery<ICompany[], ApiError>(
		["GetCompanies"],
		GetCompanies,
		{
			onSuccess: checkInitialCompany,
			placeholderData: [],
			enabled: !!token
		}
	);

	const company = useMemo(() => {
		if (companiesQuery.data && companiesQuery.data.length > 0) {
			return companiesQuery.data.find(i => i.id === selectedCompany?.id);
		}
		return undefined;
	}, [companiesQuery.data, selectedCompany]);

	return ({
		companies: companiesQuery.data || [],
		company,
		setSelectedCompany
	});
}